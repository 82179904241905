<template>
  <div class="app-container">
    <CrudTable entity="PackageRateManual" :columns="columns" :generate-summary="generateSummary" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTableDownload';
import { hasEditPermissionForCode } from '@/utils/permission';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [{ field: 'name' }, { field: 'year', width: 100 }]
    };
  },
  methods: {
    generateSummary({ name }) {
      return `${name}`;
    },
    hasNoEditPermission() {
      return !hasEditPermissionForCode(this.entity, this.$store.getters.permissions);
    }
  }
};
</script>

<style scoped></style>
